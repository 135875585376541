<template>
    <div class="course-schedule-form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="110px" class="rule-form">
            <el-form-item label="排课方式" prop="classArrangement">
                <el-radio v-model="ruleForm.classArrangement" label="1">手工排课</el-radio>
            </el-form-item>
            <el-row>
                <el-col>
                    <el-form-item label="排课班级"  prop="scheduleClassId">
                        <ElSelectTree
                            class="rightdivwidth"
                            v-model="ruleForm.scheduleClassId"
                            :data="schedulingClassesList"
                            :props="{
                                value: 'id',
                                label: (data) => data.name,
                                children: 'child'
                            }"
                            filterable
                            @change="scheduleClassIdChange"
                        />
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="11">
                    <el-form-item label="重复方式" prop="repeatClass">
                        <el-radio-group  class="rightdivwidth" v-model="ruleForm.repeatClass" @change="handlerRepeatClassChange" >
                            <el-radio label="1">每周重复</el-radio>
                            <el-radio label="2">单周重复</el-radio>
                            <el-radio label="3">双周重复</el-radio>
                            <el-radio label="0">不重复</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="11">
                    <el-form-item label="科目" prop="subjectId" label-position="right">
                        <el-select aria-disabled="true"
                            v-model="ruleForm.subjectId"
                            filterable
                            placeholder="请选择"
                            class="rightdivwidth">
                            <el-option
                                v-for="item in subjectList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="上课教师" prop="classTeacherId">
                        <!-- <ElSelectTree
                            v-model="ruleForm.classTeacherId"
                            filterable
                            :data="teacherList"
                            :props="{
                                value: 'id',
                                label: (data) => data.name,
                                children: 'children'
                            }"

                        /> -->
                        <select-tree
                            v-model="ruleForm.classTeacherId"
                            :data="teacherList"
                            :props="{
                                value: 'id',
                                label: (data) => data.name,
                                children: 'children'
                            }"
                            class="rightdivwidth"
                        >
                            </select-tree>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="11">
                    <el-form-item label="督导教师" prop="supervisingTeacherId">
                        <select-tree
                            v-model="ruleForm.supervisingTeacherId"
                            :multiple="true"
                            class="rightdivwidth"
                            :data="supervisingTeacherList"
                            :props="{
                                value: 'id',
                                label: (data) => data.name,
                                children: 'children'
                            }"
                        >
                        </select-tree>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row></el-row>

            <el-row v-if="ruleForm.repeatClass !== '0'">
                <el-col :span="11">
                    <el-form-item label="开始日期" prop="startScheduleTime">
                        <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="ruleForm.startScheduleTime"
                            value-format="yyyy-MM-dd"
                            class="rightdivwidth"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="ruleForm.repeatClass !== '0'">
                <el-col :span="11">
                    <el-form-item label="结束日期" prop="endScheduleTime">
                        <el-date-picker
                            type="date"
                            :picker-options="expireTimeOPtion"
                            placeholder="选择日期"
                            v-model="ruleForm.endScheduleTime"
                            value-format="yyyy-MM-dd"
                            class="rightdivwidth"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-else>
                <el-col :span="11">
                    <el-form-item label="排课日期" prop="scheduleDate">
                        <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="ruleForm.scheduleDate"
                            value-format="yyyy-MM-dd"
                            class="rightdivwidth"
                            @change="handlerScheduleDateChange"
                            ></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>

                <el-form-item label="上课时间">
                    <div class="class-week-wrapper" :style="{'max-width': this.classTime == '1' ? '164px' : '1135px'}">
                        <class-week
                            v-for="(item, index) of classWeekList"
                            :key="index"
                            :data="item"
                            :classTime="classTime"
                        >
                        </class-week>
                    </div>
                </el-form-item>
        </el-form>
        <div class="line"></div>
        <div class="btn-option">
                    <el-button @click="cancellationForm('ruleForm')">取消</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm', 'continueScheduling')">保存并继续排课</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    import ElSelectTree from 'el-select-tree';
    Vue.use(ElSelectTree);
    import ClassWeek from './ClassWeek/index.vue';

    import virtualList from 'vue-virtual-scroll-list'
    import ElOptionNode from './VirtulaList/ElOptionNode.vue';
    import SelectTree from '../Sub/SelectTree.vue'

    export default {
        name: "CourseScheduleForm",
        components: {
            ElSelectTree,
            ClassWeek,
            'virtual-list': virtualList,
            SelectTree
        },
        props: {
            ruleForm: Object,
            rules: Object,
            schedulingClassesList: Array,
            subjectList: Array,
            teacherList: Array,
            supervisingTeacherList: Array,
            classWeekList: Array,
            classTime: String
        },
        data () {
            return {
                itemComponent: ElOptionNode,
                repeatClass: '',
                repeatClassList: ['1', '2', '3'],
                type: false,
                expireTimeOPtion: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    },
                }
            }
        },
        methods: {
            cancellationForm () {
                this.$emit('cancellationForm')
            },
            submitForm (formName, type) {
                let _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {

                        if (this.ruleForm.repeatClass != '0') {
                            if (new Date(this.ruleForm.startScheduleTime).getTime() <= new Date(this.ruleForm.endScheduleTime)) {
                                _this.$emit('submitForm', type)
                            } else {
                                this.$message.error('开始排课日期不能大于结束排课日期')
                                return false;
                            }
                        } else {
                            _this.$emit('submitForm', type)
                        }
                    } else {
                        return false;
                    }
                });
            },
            scheduleClassIdChange () {
                this.$emit('scheduleClassIdChange')
            },
            handlerRepeatClassChange () {
                // if (this.repeatClassList.indexOf(this.ruleForm.repeatClass) == -1 && this.repeatClass) {
                //     this.$emit('handlerRepeatClassChange', this.ruleForm.repeatClass)
                //     this.repeatClass = this.ruleForm.repeatClass;
                // } else if (this.repeatClassList.indexOf(this.ruleForm.repeatClass) > -1 && this.repeatClass == '0'){
                //     this.$emit('handlerRepeatClassChange', this.ruleForm.repeatClass)
                //     this.repeatClass = this.ruleForm.repeatClass;
                // } else {
                //     console.log('0000000')
                //     this.repeatClass = this.ruleForm.repeatClass;
                // }

                this.$emit('handlerRepeatClassChange', this.ruleForm.repeatClass)
                    this.repeatClass = this.ruleForm.repeatClass;
            },
            handlerScheduleDateChange () {
                this.$emit('handlerScheduleDateChange')
            },
            resetForm() {
                this.$refs['ruleForm'].resetFields();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @media (max-width: 1024px ) {
        .course-schedule-form {
            box-sizing: border-box;
            padding: 60px 70px 0;
            height: calc(100vh - 160px);
            overflow: auto;
            display:flex;
            justify-content: center;
        }
    }
    @media (max-width: 1280px ) and (min-width: 1025px){
        .course-schedule-form {
            box-sizing: border-box;
            padding: 60px 70px 0;
            height: calc(100vh - 160px);
            overflow: auto;
            display:flex;
            justify-content: center;
        }
    }
    @media (max-width: 1740px ) and (min-width: 1281px){
        .course-schedule-form {
            box-sizing: border-box;
            padding: 20px 70px 0;
            overflow: auto;
            height: calc(100vh - 160px);
        }
    }

    @media (min-width: 1741px ) {
        .course-schedule-form {
            box-sizing: border-box;
            padding: 50px 190px 0;
            overflow: auto;
            height: calc(100vh - 160px);


        }
    }
    .rule-form{
        margin: auto;
        width: 705px;
        // background-color: rebeccapurple;
        // .el-form-item__label{
        //     width: 114px !important;
        // }
        .rightdivwidth{
            width: 602px;
            margin-left: -10px;
        }
    }

    .line {
        width: 100%;
        border-bottom: 1px solid #EDF0F2;
        margin-bottom: 18px;
    }
    .class-week-wrapper {
        width: 656px;
        margin-left: -12px;
        //height: 86px;
        //overflow: auto;
        display: flex;
        flex-wrap: wrap;
        // background-color: #FAFAFA;
    }
    .btn-option {
        text-align: center;
        margin-bottom: 20px;
        // position: fixed;
        // bottom: 10px;
        // display: flex;
        // width: 91%;
        // overflow: hidden;
        // height: 54px;
        // // z-index: 2;
        // box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.1);
        // justify-content: center;
        // align-items: center;
        // padding: 0px 0;
        // margin-left: -100px;
    }
</style>
