<template>
    <div class="course-list-layout">
        <table-th wh="136px" style="background-color: #F2F2F2;">
            <div class="class-patrol" @click="handlerClassPatrolClick" v-if="isTourClass != '0'">
                <img :src="classPatrol_off" alt="未巡课" v-if="queryList.isTourClass !== '0'"/>
                <img :src="classPatrol_on" alt="巡课" v-else/>
                <span :class="['title', {current: queryList.isTourClass == '0'}]">未巡课</span>
            </div>
        </table-th>
        <course-list-item
            v-for="(item, index) of courseList"
            :key="index"
            :data="item"
            :type="type"
        >
        </course-list-item>
    </div>
</template>
<script>
    import TableTh from './Sub/TableTh.vue';
    import courseListItem from './courseListItem.vue';
    export default {
        name: "CourseList",
        components: {
            TableTh,
            courseListItem
        },
        props: {
            queryList: Object,
            courseList: Array,
            isTourClass: String,
            type:String
        },
        computed: {
            classPatrol_off () {
                return require('@/assets/images/classPatrol_off.png')
            },
            classPatrol_on () {
                return require('@/assets/images/classPatrol_on.png')
            }
        },
        methods: {
            handlerClassPatrolClick () {
                this.$eventDispatch('handlerClassPatrolClick')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .course-list-layout {
        background-color: #2288F1;
        .class-patrol {
            display: flex;
            align-items: center;
            cursor: pointer;
            .title {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #9DA1A6;
            }
            .title.current {
                color: #3C7FFF;
            }
        }
    }
</style>