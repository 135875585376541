<template>
    <div :class="['class-week-item',{ current: dataId == '1'}]">
        <div class="item" @click="del">
            <el-tooltip :content="itemData.title" placement="top">
              <span>{{ itemData.title }}</span>
            </el-tooltip>
            <img class="del" :src="delImg" alt="删除排课"/>
        </div>
    </div>
</template>
<script>
export default {
    name: "ClassWeekItem",
    props: {
        itemData: Object,
        dataId: String,
        idx: Number
    },
    computed: {
        delImg () {
            return require('@/assets/images/TeachingResearchCourseScheduleTemp/del.png')
        }
    },
    methods: {
        del () {
            this.$confirm('确定删除该排课?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$eventDispatch('delClassWeekItem',{ dataId: this.dataId })
            }).catch(() => {
                
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .class-week-item {
        width: 164px;
        height: 40px;
        border: 1px solid #EDF0F2;
        margin-left: -1px;
		margin-top: -1px;
       
        box-sizing: border-box;
        padding: 5px;
        .item {
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 4px;
            cursor: pointer;

            span {
                width: 152px;
                font-size: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #363B40;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .del {
                margin-left: 10px;
                width: 12px;
                height: 2px;
            }
        }
        
    }
    .class-week-item.current {
        margin-left: 0px;
    }
</style>