<template>
    <div class="course-table-title">
        <div class="table-title">
            <img src="@/assets/images/coursetab.png" alt="" srcset="">
            <span>课程表</span>
        </div>
        <div class="table-title" v-if="type === '1'">
            <span>
                班级: {{ classAndTeacher.className }}
            </span>
            <el-tooltip class="item" effect="dark" :content="classAndTeacher.teacherName" placement="top-start">
                <span class="teacher" >
                班主任: {{ classAndTeacher.teacherName }}
            </span>
            </el-tooltip>   
        </div>
        <div class="table-title" v-if="type === '2'">
            <span>
                教师: {{ classAndTeacher.teacherName }}
            </span>
        </div>
        <div class="table-title" v-if="type === '3'">
            <span>
                年级: {{ classAndTeacher.className }}
            </span>
        </div>
        
    </div>
</template>
<script>

    export default {
        name: "CourseTableTitle",
        props: {
            classAndTeacher: Object,
            type:String
        },
        computed: {
           
        },
        mounted(){
        // console.log("王园园这是什么数据",this.classAndTeacher)
    },
        methods: {
            handlerClassPatrolClick () {
                this.$eventDispatch('handlerClassPatrolClick')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .course-table-title {
        height: 48px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .table-title{
            display: flex;
            align-items: center;
            height: 48px;
            margin-right: 10px;
            img{
                width: 14px;
                height: 16px;
                margin-left: 21px;
                // margin-top: 16px;
            }
            span{
                color: #2B2E33;
                font-size: 16px;
                margin-left: 10px;
            }
            .teacher{
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap
            }
        }

        .class-patrol {
            display: flex;
            align-items: center;
            
            .title {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                // color: #808487;
                // color: #FFFFFF;
                color:#3C7FFF;
            }
            .title.current {
                color: #499DF2;
            }
        }
    }
</style>