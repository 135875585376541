<template>
    <div class="class-week-layout">
        <div :class="['title', {current: data.week == classTime}]">{{ data.label || '排课日期'}}</div>
        <class-week-item
            v-if="data.title"
            :itemData="data"
            :dataId="data.week"
        >
        </class-week-item>
        <div
            v-else
            :class="['add', {current: data.week == '1'}]" @click="addLessonTime(data)">
            <img :src="addImg" alt="添加课程" />
            <span class="add-lesson-time">添加课时</span>
        </div>
    </div>
</template>
<script>
    import ClassWeekItem from './Item.vue';

    export default {
        name: "ClassWeek",
        components: {
            ClassWeekItem
        },
        props: {
            data: Object,
            classTime: String
        },
        computed: {
            addImg () {
                return require('@/assets/images/TeachingResearchCourseScheduleTemp/add.png')
            }
        },
        methods: {
            addLessonTime (data) {
                this.$eventDispatch('addLessonTime', data)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .class-week-layout {
        width: 164px;
        .title {
            background-color: #FAFAFA;
            text-align: center;
            border-top: 1px solid #EDF0F2;
            border-left: 1px solid #EDF0F2;
            border-bottom: 1px solid #EDF0F2;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #808487;
            box-sizing: border-box;
            padding: 0 14px;
        }
        .title.current {
            border-right: 1px solid #EDF0F2;
        }
        .add {
            width: 162px;
            height: 42px;
            border: 1px solid #EDF0F2;
		    margin-top: -1px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            
            img {
                width: 12px;
                height: 12px;
            }
            .add-lesson-time {
                font-size: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2B2E33;
                padding-left: 9px;
            }
        }
        .add.current {
            margin-left: 0;
        }
    }
</style>